<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        Use the Odyssey results for the dipoles of the carvone, dihydrocarveol, and limonene to
        answer the following questions:
      </p>

      <p class="mb-n3">a) Which compound will travel the furthest up the TLC plate?</p>

      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="ml-6 mb-0">
        <v-radio
          v-for="option in options1"
          :key="'pt-1-' + option.value"
          class="my-1 ml-3"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-1">
        b) The compound that travels the furthest up the TLC plate will have the ________________
        R<sub>f</sub> value.
      </p>

      <p class="mb-3">
        <v-select
          v-model="inputs.input2"
          style="display: inline-block; max-width: 250px"
          class="ml-1"
          :items="options2"
          item-text="text"
          item-value="value"
          label="Select an option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-n3">
        c) View the electrostatic potential maps of each molecule by clicking on the
        <v-img
          style="display: inline-block; max-width: 30px"
          src="/img/assignments/UCIrvine/q2s2q6Icon.png"
        />
        icon. Which molecule has the least amount of red and blue coloring?
      </p>

      <v-radio-group v-model="inputs.input3" :disabled="!allowEditing" class="ml-6 mb-0">
        <v-radio
          v-for="option in options1"
          :key="'pt-3-' + option.value"
          class="my-1 ml-3"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">d) This is because this molecule is:</p>

      <v-radio-group v-model="inputs.input4" :disabled="!allowEditing" class="mb-0 ml-6">
        <v-radio
          v-for="option in options3"
          :key="'pt-4-' + option.value"
          class="my-1 ml-3"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-n3">
        e) Do the dipoles in Odyssey correctly predict the R<sub>f</sub> values of the other two
        compounds?
      </p>

      <v-radio-group v-model="inputs.input5" :disabled="!allowEditing" class="ml-6 mb-0">
        <v-radio
          v-for="option in options4"
          :key="'pt-5-' + option.value"
          class="my-1 ml-3"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'ChemUCI1LDQ2S2Q6',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
      },
      options1: [
        {text: 'Carvone', value: 'carvone'},
        {text: 'Dihydrocarveol ', value: 'dihydro'},
        {text: 'Limonene', value: 'limo'},
      ],
      options2: [
        {text: 'smallest', value: 'smallest'},
        {text: 'middle', value: 'middle'},
        {text: 'largest', value: 'largest'},
      ],
      options3: [
        {text: 'the least polar', value: 'least'},
        {text: 'the most charged', value: 'most'},
        {text: 'the most attracted to the silica of the TLC plate', value: 'mostAttracted'},
        {text: 'the lightest', value: 'lightest'},
      ],
      options4: [
        {text: "No, dihydrocarveol's dipole should be larger", value: 'dihydroLarger'},
        {text: "No, limonene's dipole should have been larger", value: 'limoLarger'},
        {text: "No, carvone's dipole should have been larger", value: 'carvoneLarger'},
        {
          text: 'Yes, dihydrocarveol is the most polar and has the smallest Rf',
          value: 'yesDihydro',
        },
        {text: 'Yes, limonene is the most polar and has the smallest Rf', value: 'yesLimo'},
        {text: 'Yes, carvone is the most polar and has the smallest Rf', value: 'yesCarvone'},
      ],
    };
  },
};
</script>
<style scoped></style>
